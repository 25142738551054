<template>
  <div class="pc">
    <!-- 视频预览 -->
    <transition name="fade" v-if="showVideoFlag">
      <div class="video-pop" style="display: block">
        <div class="pop-bg" @click.stop="closeHomeVideos()"></div>
        <div class="pop-box">
          <img
            class="close"
            src="../../assets/closevideo.png"
            @click.stop="closeHomeVideos()"
          />
          <video
            ref="homevideo"
            width="100%"
            preload="auto"
            controls=""
            :src="navbackgroundList[ivideo].videoAbsolutePath"
            class="video"
          ></video>
        </div>
      </div>
    </transition>
    <div class="nav">
      <div v-show="pagePath == '/'">
        <swiper
          :options="swiperOption"
          ref="mySwiper"
          v-if="navbackgroundList.length > 0"
        >
          <swiper-slide v-for="(item, index) in navbackgroundList" :key="index">
            <img
              class="nav-bg"
              :src="item.absolutePath"
              :style="{
                cursor:
                  item.homeType == 1 || item.homeType == 2 || item.homeType == 3
                    ? 'pointer'
                    : '',
              }"
            />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>

        <div class="home-first">
          <div>向下滚动</div>
          <img
            class="down"
            src="../../assets/icon_sy_xl.png"
            @click="gotoSecondPage()"
          />
        </div>
      </div>
      <div v-show="pagePath != '/'">
        <img class="nav-bg1" src="../../assets/icon_nav_bg.png" />
      </div>
      <!-- 透明背景 -->

      <div class="nav-top-background">
        <div
          class="nav-top"
          :class="{ 'fix-nav': navBarFixed }"
          v-if="!navBarFixed"
        >
          <router-link to="/">
            <img class="nav-icon" src="../../assets/icon_sjhl.png" />
          </router-link>

          <div class="nav-btn">
            <div
              class="nav-btn-item"
              @mouseover="show = 1"
              @mouseout="show = -1"
            >
              <router-link to="/impression">视界印象</router-link>
              <img
                :src="
                  show == 1
                    ? require('../../assets/icon_nav_up.png')
                    : require('../../assets/icon_nav_down.png')
                "
              />
              <transition name="drop-down" mode="out-in">
                <div
                  class="nav-btn-list"
                  style="background-size: 100% 100%"
                  v-show="show == 1"
                >
                  <li @click="toguide('/impression', 'impression-main1')">
                    企业介绍
                  </li>
                  <li @click="toguide('/impression', 'impression-develop1')">
                    发展历程
                  </li>
                  <li @click="toguide('/impression', 'team1')">团队管理</li>
                  <li @click="toguide('/impression', 'culture1')">企业文化</li>
                  <li @click="toguide('/impression', 'relation1')">联系我们</li>
                </div>
              </transition>
            </div>
            <div
              class="nav-btn-item"
              @mouseover="show = 2"
              @mouseout="show = -1"
            >
              <router-link to="/platform">产品矩阵</router-link>
              <img
                :src="
                  show == 2
                    ? require('../../assets/icon_nav_up.png')
                    : require('../../assets/icon_nav_down.png')
                "
              />
              <transition name="drop-down">
                <div class="nav-btn-list" v-show="show == 2">
                  <li @click="toguides('/platform', 1)">视界观APP</li>
                  <li @click="toguides('/platform', 2)">新国货电商</li>
                </div>
              </transition>
            </div>
            <div
              class="nav-btn-item"
              @mouseover="show = 3"
              @mouseout="show = -1"
            >
              <router-link to="/Dynamic">新闻中心</router-link>
              <img
                :src="
                  show == 3
                    ? require('../../assets/icon_nav_up.png')
                    : require('../../assets/icon_nav_down.png')
                "
              />
              <transition name="drop-down">
                <div class="nav-btn-list" v-show="show == 3">
                  <li @click="toguide('/Cases', 'cases')">精品案例</li>
                  <li @click="toguide('/Dynamic', 'latest')">企业新闻</li>
                  <li @click="toguide('/Dynamic', 'news')">视界热点</li>
                  <li @click="toguide('/Dynamic', 'focus')">聚焦新国货</li>
                </div>
              </transition>
            </div>
            <div
              class="nav-btn-item"
              @mouseover="show = 4"
              @mouseout="show = -1"
            >
              <router-link to="#">内容生态联盟</router-link>
              <img
                :src="
                  show == 4
                    ? require('../../assets/icon_nav_up.png')
                    : require('../../assets/icon_nav_down.png')
                "
              />
              <transition name="drop-down">
                <div class="nav-btn-list" v-show="show == 4">
                  <a
                    href="https://spider-sz.app.visionlinkmedia.cn/?tenantId=663579ea95c53cf7014e5e86c05c63df#/login"
                    class="subItem"
                    target="_blank"
                    >登录</a
                  >
                  <a
                    href="https://spider-sz.app.visionlinkmedia.cn/?tenantId=663579ea95c53cf7014e5e86c05c63df#/register"
                    class="subItem"
                    target="_blank"
                    >入驻</a
                  >
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <!-- 上滑 白色背景 -->
      <transition name="nav" mode="out-in">
        <div :class="{ 'fix-nav': navBarFixed }" v-if="navBarFixed">
          <div class="nav-top">
            <router-link to="/">
              <img class="nav-icon" src="../../assets/icon_sjhl_black.png" />
            </router-link>

            <div class="nav-btn">
              <div
                class="nav-btn-item1"
                @mouseover="show = 1"
                @mouseout="show = -1"
              >
                <router-link to="/impression">视界印象</router-link>
                <img
                  :src="
                    show == 1
                      ? require('../../assets/icon_nav_up_black.png')
                      : require('../../assets/icon_nav_down_black.png')
                  "
                />
                <transition name="drop-down" mode="out-in">
                  <div class="nav-btn-list1" v-show="show == 1">
                    <li @click="toguide('/impression', 'impression-main1')">
                      企业介绍
                    </li>
                    <li @click="toguide('/impression', 'impression-develop1')">
                      发展历程
                    </li>
                    <li @click="toguide('/impression', 'team1')">团队管理</li>
                    <li @click="toguide('/impression', 'culture1')">
                      企业文化
                    </li>
                    <li @click="toguide('/impression', 'relation1')">
                      联系我们
                    </li>
                  </div>
                </transition>
              </div>
              <div
                class="nav-btn-item1"
                @mouseover="show = 2"
                @mouseout="show = -1"
              >
                <router-link to="/platform">产品矩阵</router-link>
                <img
                  :src="
                    show == 2
                      ? require('../../assets/icon_nav_up_black.png')
                      : require('../../assets/icon_nav_down_black.png')
                  "
                />
                <transition name="drop-down">
                  <div class="nav-btn-list1" v-show="show == 2">
                    <li @click="toguides('/platform', 1)">视界观APP</li>
                    <li @click="toguides('/platform', 2)">新国货电商</li>
                  </div>
                </transition>
              </div>
              <div
                class="nav-btn-item1"
                @mouseover="show = 3"
                @mouseout="show = -1"
              >
                <router-link to="/Dynamic">新闻中心</router-link>
                <img
                  :src="
                    show == 3
                      ? require('../../assets/icon_nav_up_black.png')
                      : require('../../assets/icon_nav_down_black.png')
                  "
                />
                <transition name="drop-down">
                  <div class="nav-btn-list1" v-show="show == 3">
                    <li @click="toguide('/Cases', 'cases')">精品案例</li>
                    <li @click="toguide('/Dynamic', 'latest')">企业新闻</li>
                    <li @click="toguide('/Dynamic', 'news')">视界热点</li>
                    <li @click="toguide('/Dynamic', 'focus')">聚焦新国货</li>
                  </div>
                </transition>
              </div>
              <div
                class="nav-btn-item1"
                @mouseover="show = 4"
                @mouseout="show = -1"
              >
                <router-link to="#">内容生态联盟</router-link>
                <img
                  :src="
                    show == 4
                      ? require('../../assets/icon_nav_up_black.png')
                      : require('../../assets/icon_nav_down_black.png')
                  "
                />
                <transition name="drop-down">
                  <div class="nav-btn-list1" v-show="show == 4">
                    <a
                      href="https://spider-sz.app.visionlinkmedia.cn/?tenantId=663579ea95c53cf7014e5e86c05c63df#/login"
                      class="subItem"
                      target="_blank"
                      >登录</a
                    >
                    <a
                      href="https://spider-sz.app.visionlinkmedia.cn/?tenantId=663579ea95c53cf7014e5e86c05c63df#/register"
                      class="subItem"
                      target="_blank"
                      >入驻</a
                    >
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <router-view :key="pagePath" />
    <div id="footer">
      <div class="footer">
        <div class="footerCon">
          <div class="about about2">
            <p class="link">
              <a
                href="https://beian.miit.gov.cn/"
                target="_blank"
                style="margin-right: 4px"
                >网站备案号：苏ICP备2020052426号-1</a
              >Copyright 2021 江苏视界互联传媒有限公司
            </p>
          </div>
          <div class="about about3">
            <p class="link">地址：江苏省南京市玄武区运粮河西路101号江苏有线</p>
            <a
              class="link"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011502011236"
              target="_blank"
            >
              <img
                src="../../assets/gongan.png"
                style="width: 15px; margin-right: 4px"
              />
              苏公网安备 32011502011236号
            </a>
            <a
              class="link"
              href="http://ccm.mct.gov.cn/ccnt/sczr/service/business/emark/toDetail/cca6e731d1e54f35aa0844e732abd072"
              target="_blank"
            >
              <img
                src="../../assets/suwenwang.png"
                style="width: 15px; margin-right: 4px"
              />
              苏网文〔2020〕6098-190号
            </a>
          </div>
          <div class="telCon flexB">
            <div class="item">
              <div class="title">联系我们</div>
              <p>公司邮箱：sjg@jscnnet.com</p>
              <p>微信公众号：VisionLinkMedia</p>
            </div>
            <div class="item">
              <div class="title">合作方式</div>
              <p>广告合作：sjhl_business@jscnnet.com</p>
              <p>媒体入驻微信：JSCN_SJHL</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from "../../utils";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { swiperImage, ipHeader } from "../../api/index";

var vm = null;
export default {
  name: "pc",

  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      ivideo: "",
      showVideoFlag: false,
      send: "",
      swiperOption: {
        loop: true,
        // autoHeight: true,
        loopAdditionalSlides: 2,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        observer: true,
        observeParents: true,
        slideToClickedSlide: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        on: {
          click: function () {
            console.log(this);
            let index = this.realIndex;
            if (this.clickedIndex != undefined) {
              vm.showHomeVideo(vm.navbackgroundList[index].homeType, index);
            }
          },
        },
      },
      navbackgroundList: [],
      navBarFixed: false,
      show: -1,
    };
  },
  created() {
    vm = this;
    this.showImage();
  },
  mounted() {
    window.addEventListener("mousewheel", debounce(this.handleScroll), false);
  },
  computed: {
    pagePath() {
      // console.log(this.$route.path);
      return this.$route.path;
    },
  },
  destroyed() {
    window.removeEventListener("mousewheel", debounce(this.handleScroll));
  },

  methods: {
    closeHomeVideos() {
      this.showVideoFlag = !this.showVideoFlag;
      const mySwiper = this.$refs.mySwiper.swiper;
      if (this.showVideoFlag) {
        mySwiper.autoplay.stop();
      } else {
        mySwiper.autoplay.start();
      }
    },
    showHomeVideo(hometype, i) {
      // console.log(hometype + " " + i);
      if (hometype == 1) {
        this.showVideoFlag = !this.showVideoFlag;
        const mySwiper = this.$refs.mySwiper.swiper;
        if (this.showVideoFlag) {
          mySwiper.autoplay.stop();
        } else {
          mySwiper.autoplay.start();
        }
        this.ivideo = i;
        this.$nextTick(() => {
          var that = this;
          if (this.showVideoFlag) {
            var elevideo = document.getElementsByClassName("video")[0];
            if (elevideo) {
              elevideo.play();
              elevideo.addEventListener(
                "ended",
                function () {
                  //结束
                  console.log("播放结束");
                  that.closeHomeVideos();
                },
                false
              );
            }
          }
        });
      } else if (hometype == 2) {
        let j = this.navbackgroundList[i].linkCode;
        if (j === "100201") {
          this.$router.push({ path: "/impression" });
        } else if (j === "100202") {
          localStorage.setItem("toId", 1);
          this.$router.push({ path: "/platform" });
        } else if (j === "100203") {
          localStorage.setItem("toId", 2);
          this.$router.push({ path: "/platform" });
        } else if (j === "100204") {
          localStorage.setItem("toId", "cases");
          this.$router.push({ path: "/Cases" });
        } else if (j === "100205") {
          localStorage.setItem("toId", "latest");
          this.$router.push({ path: "/Dynamic" });
        } else if (j === "100206") {
          localStorage.setItem("toId", "news");
          this.$router.push({ path: "/Dynamic" });
        } else if (j === "100207") {
          localStorage.setItem("toId", "focus");
          this.$router.push({ path: "/Dynamic" });
        }
      } else if (hometype == 3) {
        window.open(this.navbackgroundList[i].linkAddress);
      } else {
        return;
      }
    },
    gotoSecondPage() {
      window.scrollTo({
        top: document.getElementsByClassName("nav")[0].offsetHeight,
        behavior: "smooth",
      });
    },
    /* 图片地址转换 */
    carouselImg(item) {
      return `${ipHeader}nsc/file${item}`;
    },
    showImage() {
      swiperImage()
        .then((res) => {
          this.navbackgroundList = res.data.data;
          if (
            this.navbackgroundList[0].homeType == 1 &&
            !sessionStorage.recordFirstOpenPageFlag
          ) {
            sessionStorage.setItem("recordFirstOpenPageFlag", true);
            this.$nextTick(() => {
              this.showHomeVideo(1, 0);
            });
          }
        })
        .then((err) => {
          console.log(err);
        });
    },
    handleScroll(e) {
      let direction = e.deltaY > 0 ? "down" : "up";
      if (direction == "down" && e.deltaY > 0) {
        this.navBarFixed = false;
      } else if (
        document.documentElement.scrollTop <
        document.getElementsByClassName("nav")[0].offsetHeight - 20
      ) {
        this.navBarFixed = false;
      } else {
        this.navBarFixed = true;
      }
    },
    gotoHome() {
      this.$router.push({ path: "/" });
    },
    toguide(page, selectID) {
      if (page == this.$route.path) {
        let toElement = document.getElementById(selectID);
        toElement.scrollIntoView(true);
      } else {
        localStorage.setItem("toId", selectID);
        this.$router.push({ path: page });
      }
    },
    toguides(page, selectID) {
      if (page == this.$route.path) {
        this.send = selectID;
        this.$bus.$emit("a", this.send);
      } else {
        localStorage.setItem("toId", selectID);
        this.$router.push({ path: page });
      }
    },
  },
};
</script>

<style>
#app {
  width: 100%;
}
.nav-enter-active,
.nav-leave-active {
  opacity: 1;
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.1s;
  opacity: 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0.1s;
  -ms-transform-origin: center top;
  transform-origin: center top;
}
.nav-enter,
.nav-leave-to {
  opacity: 0.1s cubic-bezier(0.1, 1, 0.1, 1) 0.1s;
  -ms-transform: scaleY(0);
  transform: scaleY(0);
}
/* 视频模块 */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.video-pop {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: none;
}
.video-pop .pop-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
}
.video-pop .pop-box {
  width: 1200px;
  height: 675px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  background: #000;
  box-sizing: border-box;
}
.video-pop .pop-box .close {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 2;
}
.video-pop .pop-box .video {
  width: 100%;
  height: 100%;
}
.nav {
  position: relative;
  width: 100%;
}
.nav-bg {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.nav-bg1 {
  width: 100%;
  height: 600px;
  object-fit: cover;
}
.nav-video {
  position: relative;
}
.home-first {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.home-first div {
  color: #fff;
  font-weight: bold;
  font-family: "siyuan-Medium";
  font-size: 18px;
  margin-bottom: 12px;
  z-index: 2;
}
.down {
  /* width: 28px; */
  width: 40px;
  margin-bottom: 46px;
  cursor: pointer;
  z-index: 2;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 50px;
  right: 50px;
  width: auto;
  left: unset;
}
.swiper-pagination-bullet {
  width: 20px;
  height: 10px;
  border-radius: 4px;
  display: inline-block;
  background-color: rgb(239, 239, 239);
}
/*自定义分页器激活时的样式表现*/
.swiper-pagination-bullet-active {
  width: 48px;
  height: 10px;
  display: inline-block;
  border-radius: 4px;
  background-color: white;
}
.nav-top-background {
  position: absolute;
  top: 0;
  width: 100vw;
}
.nav-top {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -700px;
  display: flex;
  /* padding-top: 22px; */
  justify-content: space-around;
  align-items: center;
  width: 1400px;
  height: 68px;
  z-index: 2;
}
.fix-nav {
  position: fixed;
  top: 0;
  z-index: 999;
  background: white;
  color: #333;
  width: 100%;
  height: 68px;
  border-bottom: 1px solid #e8e8e8;
}
.nav-icon {
  width: 117px;
}
.nav-btn {
  display: flex;
  align-items: center;
}
.nav-btn-item,
.nav-btn-item1 {
  display: flex;
  align-items: center;
  margin-left: 26px;
  width: 120px;
}
.nav-btn-item img,
.nav-btn-item1 img {
  height: 7px;
  margin-left: 6px;
}
.nav-btn-item a,
.router-link-active {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 2px 2px 4px #565656;
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.nav-btn-item1 a,
.router-link-active {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  text-shadow: none;
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
}
.nav-btn-list,
.nav-btn-list1 {
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  background-position-y: bottom;
  background-repeat: no-repeat;
  position: absolute;
  top: 50px;
  padding: 10px 0px;
  width: 103px;
  text-align: center;
  border-radius: 0 0 4px 4px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nav-btn-list {
  background-image: url(../../assets/icon_nav_list_bg.png);
  -moz-border-image: url(../../assets/icon_nav_list_bg.png) 0;
}
.nav-btn-list1 {
  background: white;
}
.nav-btn-list li,
.nav-btn-list1 li {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 2px 2px 4px #565656;
  margin: 12px 0px;
  cursor: pointer;
}
.nav-btn-list1 li {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  text-shadow: none;
  margin: 12px 0px;
}
.nav-btn-list a,
.nav-btn-list1 a {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 2px 2px 4px #565656;
  margin: 6px 0px;
}
.nav-btn-list1 a {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  text-shadow: none;
  margin: 6px 0px;
}
.drop-down-enter-active,
.drop-down-leave-active {
  opacity: 1;
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0.1s;
  opacity: 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0.1s;
  -ms-transform-origin: center top;
  transform-origin: center top;
}
.drop-down-enter,
.drop-down-leave-to {
  opacity: 0.1s cubic-bezier(0.23, 1, 0.32, 1) 0.1s;
  -ms-transform: scaleY(0);
  transform: scaleY(0);
}

.footer {
  padding: 20px 0 25px;
  background: #333;
  color: #fff;
  height: auto !important;
}
.footer .footerCon {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.footer a {
  color: #fff;
}
.footer .about {
  font-size: 12px;
  line-height: 13px;
  text-align: center;
  margin: 0 auto;
}
.footer .about ~ .about {
  padding-top: 10px;
  display: flex;
  align-items: center;
}
.footer .about .link {
  display: inline-block;
  padding: 0 8px;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  transition: all 0.3s ease-out;
  display: flex;
  align-items: center;
}
.footer .about .link ~ .link {
  border-left: 0;
}
.footer .about a[href]:hover {
  color: rgb(153, 223, 248);
}
.footer .telCon {
  font-size: 12px;
  line-height: 13px;
  align-items: start;
  padding-top: 20px;
  width: 600px;
  /* width: 450px; */
  margin: 0 auto;
}
.footer .telCon .title {
  font-size: 14x;
  line-height: 15px;
  padding-bottom: 15px;
}
.footer .telCon p {
  display: block;
  font-size: 16px;
}
.footer .telCon p ~ p {
  padding-top: 12px;
}
.flexB {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pc"},[(_vm.showVideoFlag)?_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"video-pop",staticStyle:{"display":"block"}},[_c('div',{staticClass:"pop-bg",on:{"click":function($event){$event.stopPropagation();return _vm.closeHomeVideos()}}}),_c('div',{staticClass:"pop-box"},[_c('img',{staticClass:"close",attrs:{"src":require("../../assets/closevideo.png")},on:{"click":function($event){$event.stopPropagation();return _vm.closeHomeVideos()}}}),_c('video',{ref:"homevideo",staticClass:"video",attrs:{"width":"100%","preload":"auto","controls":"","src":_vm.navbackgroundList[_vm.ivideo].videoAbsolutePath}})])])]):_vm._e(),_c('div',{staticClass:"nav"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pagePath == '/'),expression:"pagePath == '/'"}]},[(_vm.navbackgroundList.length > 0)?_c('swiper',{ref:"mySwiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.navbackgroundList),function(item,index){return _c('swiper-slide',{key:index},[_c('img',{staticClass:"nav-bg",style:({
              cursor:
                item.homeType == 1 || item.homeType == 2 || item.homeType == 3
                  ? 'pointer'
                  : '',
            }),attrs:{"src":item.absolutePath}})])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2):_vm._e(),_c('div',{staticClass:"home-first"},[_c('div',[_vm._v("向下滚动")]),_c('img',{staticClass:"down",attrs:{"src":require("../../assets/icon_sy_xl.png")},on:{"click":function($event){return _vm.gotoSecondPage()}}})])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pagePath != '/'),expression:"pagePath != '/'"}]},[_c('img',{staticClass:"nav-bg1",attrs:{"src":require("../../assets/icon_nav_bg.png")}})]),_c('div',{staticClass:"nav-top-background"},[(!_vm.navBarFixed)?_c('div',{staticClass:"nav-top",class:{ 'fix-nav': _vm.navBarFixed }},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("../../assets/icon_sjhl.png")}})]),_c('div',{staticClass:"nav-btn"},[_c('div',{staticClass:"nav-btn-item",on:{"mouseover":function($event){_vm.show = 1},"mouseout":function($event){_vm.show = -1}}},[_c('router-link',{attrs:{"to":"/impression"}},[_vm._v("视界印象")]),_c('img',{attrs:{"src":_vm.show == 1
                  ? require('../../assets/icon_nav_up.png')
                  : require('../../assets/icon_nav_down.png')}}),_c('transition',{attrs:{"name":"drop-down","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show == 1),expression:"show == 1"}],staticClass:"nav-btn-list",staticStyle:{"background-size":"100% 100%"}},[_c('li',{on:{"click":function($event){return _vm.toguide('/impression', 'impression-main1')}}},[_vm._v(" 企业介绍 ")]),_c('li',{on:{"click":function($event){return _vm.toguide('/impression', 'impression-develop1')}}},[_vm._v(" 发展历程 ")]),_c('li',{on:{"click":function($event){return _vm.toguide('/impression', 'team1')}}},[_vm._v("团队管理")]),_c('li',{on:{"click":function($event){return _vm.toguide('/impression', 'culture1')}}},[_vm._v("企业文化")]),_c('li',{on:{"click":function($event){return _vm.toguide('/impression', 'relation1')}}},[_vm._v("联系我们")])])])],1),_c('div',{staticClass:"nav-btn-item",on:{"mouseover":function($event){_vm.show = 2},"mouseout":function($event){_vm.show = -1}}},[_c('router-link',{attrs:{"to":"/platform"}},[_vm._v("产品矩阵")]),_c('img',{attrs:{"src":_vm.show == 2
                  ? require('../../assets/icon_nav_up.png')
                  : require('../../assets/icon_nav_down.png')}}),_c('transition',{attrs:{"name":"drop-down"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show == 2),expression:"show == 2"}],staticClass:"nav-btn-list"},[_c('li',{on:{"click":function($event){return _vm.toguides('/platform', 1)}}},[_vm._v("视界观APP")]),_c('li',{on:{"click":function($event){return _vm.toguides('/platform', 2)}}},[_vm._v("新国货电商")])])])],1),_c('div',{staticClass:"nav-btn-item",on:{"mouseover":function($event){_vm.show = 3},"mouseout":function($event){_vm.show = -1}}},[_c('router-link',{attrs:{"to":"/Dynamic"}},[_vm._v("新闻中心")]),_c('img',{attrs:{"src":_vm.show == 3
                  ? require('../../assets/icon_nav_up.png')
                  : require('../../assets/icon_nav_down.png')}}),_c('transition',{attrs:{"name":"drop-down"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show == 3),expression:"show == 3"}],staticClass:"nav-btn-list"},[_c('li',{on:{"click":function($event){return _vm.toguide('/Cases', 'cases')}}},[_vm._v("精品案例")]),_c('li',{on:{"click":function($event){return _vm.toguide('/Dynamic', 'latest')}}},[_vm._v("企业新闻")]),_c('li',{on:{"click":function($event){return _vm.toguide('/Dynamic', 'news')}}},[_vm._v("视界热点")]),_c('li',{on:{"click":function($event){return _vm.toguide('/Dynamic', 'focus')}}},[_vm._v("聚焦新国货")])])])],1),_c('div',{staticClass:"nav-btn-item",on:{"mouseover":function($event){_vm.show = 4},"mouseout":function($event){_vm.show = -1}}},[_c('router-link',{attrs:{"to":"#"}},[_vm._v("内容生态联盟")]),_c('img',{attrs:{"src":_vm.show == 4
                  ? require('../../assets/icon_nav_up.png')
                  : require('../../assets/icon_nav_down.png')}}),_c('transition',{attrs:{"name":"drop-down"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show == 4),expression:"show == 4"}],staticClass:"nav-btn-list"},[_c('a',{staticClass:"subItem",attrs:{"href":"https://spider-sz.app.visionlinkmedia.cn/?tenantId=663579ea95c53cf7014e5e86c05c63df#/login","target":"_blank"}},[_vm._v("登录")]),_c('a',{staticClass:"subItem",attrs:{"href":"https://spider-sz.app.visionlinkmedia.cn/?tenantId=663579ea95c53cf7014e5e86c05c63df#/register","target":"_blank"}},[_vm._v("入驻")])])])],1)])],1):_vm._e()]),_c('transition',{attrs:{"name":"nav","mode":"out-in"}},[(_vm.navBarFixed)?_c('div',{class:{ 'fix-nav': _vm.navBarFixed }},[_c('div',{staticClass:"nav-top"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"nav-icon",attrs:{"src":require("../../assets/icon_sjhl_black.png")}})]),_c('div',{staticClass:"nav-btn"},[_c('div',{staticClass:"nav-btn-item1",on:{"mouseover":function($event){_vm.show = 1},"mouseout":function($event){_vm.show = -1}}},[_c('router-link',{attrs:{"to":"/impression"}},[_vm._v("视界印象")]),_c('img',{attrs:{"src":_vm.show == 1
                    ? require('../../assets/icon_nav_up_black.png')
                    : require('../../assets/icon_nav_down_black.png')}}),_c('transition',{attrs:{"name":"drop-down","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show == 1),expression:"show == 1"}],staticClass:"nav-btn-list1"},[_c('li',{on:{"click":function($event){return _vm.toguide('/impression', 'impression-main1')}}},[_vm._v(" 企业介绍 ")]),_c('li',{on:{"click":function($event){return _vm.toguide('/impression', 'impression-develop1')}}},[_vm._v(" 发展历程 ")]),_c('li',{on:{"click":function($event){return _vm.toguide('/impression', 'team1')}}},[_vm._v("团队管理")]),_c('li',{on:{"click":function($event){return _vm.toguide('/impression', 'culture1')}}},[_vm._v(" 企业文化 ")]),_c('li',{on:{"click":function($event){return _vm.toguide('/impression', 'relation1')}}},[_vm._v(" 联系我们 ")])])])],1),_c('div',{staticClass:"nav-btn-item1",on:{"mouseover":function($event){_vm.show = 2},"mouseout":function($event){_vm.show = -1}}},[_c('router-link',{attrs:{"to":"/platform"}},[_vm._v("产品矩阵")]),_c('img',{attrs:{"src":_vm.show == 2
                    ? require('../../assets/icon_nav_up_black.png')
                    : require('../../assets/icon_nav_down_black.png')}}),_c('transition',{attrs:{"name":"drop-down"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show == 2),expression:"show == 2"}],staticClass:"nav-btn-list1"},[_c('li',{on:{"click":function($event){return _vm.toguides('/platform', 1)}}},[_vm._v("视界观APP")]),_c('li',{on:{"click":function($event){return _vm.toguides('/platform', 2)}}},[_vm._v("新国货电商")])])])],1),_c('div',{staticClass:"nav-btn-item1",on:{"mouseover":function($event){_vm.show = 3},"mouseout":function($event){_vm.show = -1}}},[_c('router-link',{attrs:{"to":"/Dynamic"}},[_vm._v("新闻中心")]),_c('img',{attrs:{"src":_vm.show == 3
                    ? require('../../assets/icon_nav_up_black.png')
                    : require('../../assets/icon_nav_down_black.png')}}),_c('transition',{attrs:{"name":"drop-down"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show == 3),expression:"show == 3"}],staticClass:"nav-btn-list1"},[_c('li',{on:{"click":function($event){return _vm.toguide('/Cases', 'cases')}}},[_vm._v("精品案例")]),_c('li',{on:{"click":function($event){return _vm.toguide('/Dynamic', 'latest')}}},[_vm._v("企业新闻")]),_c('li',{on:{"click":function($event){return _vm.toguide('/Dynamic', 'news')}}},[_vm._v("视界热点")]),_c('li',{on:{"click":function($event){return _vm.toguide('/Dynamic', 'focus')}}},[_vm._v("聚焦新国货")])])])],1),_c('div',{staticClass:"nav-btn-item1",on:{"mouseover":function($event){_vm.show = 4},"mouseout":function($event){_vm.show = -1}}},[_c('router-link',{attrs:{"to":"#"}},[_vm._v("内容生态联盟")]),_c('img',{attrs:{"src":_vm.show == 4
                    ? require('../../assets/icon_nav_up_black.png')
                    : require('../../assets/icon_nav_down_black.png')}}),_c('transition',{attrs:{"name":"drop-down"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show == 4),expression:"show == 4"}],staticClass:"nav-btn-list1"},[_c('a',{staticClass:"subItem",attrs:{"href":"https://spider-sz.app.visionlinkmedia.cn/?tenantId=663579ea95c53cf7014e5e86c05c63df#/login","target":"_blank"}},[_vm._v("登录")]),_c('a',{staticClass:"subItem",attrs:{"href":"https://spider-sz.app.visionlinkmedia.cn/?tenantId=663579ea95c53cf7014e5e86c05c63df#/register","target":"_blank"}},[_vm._v("入驻")])])])],1)])],1)]):_vm._e()])],1),_c('router-view',{key:_vm.pagePath}),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"footer"}},[_c('div',{staticClass:"footer"},[_c('div',{staticClass:"footerCon"},[_c('div',{staticClass:"about about2"},[_c('p',{staticClass:"link"},[_c('a',{staticStyle:{"margin-right":"4px"},attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("网站备案号：苏ICP备2020052426号-1")]),_vm._v("Copyright 2021 江苏视界互联传媒有限公司 ")])]),_c('div',{staticClass:"about about3"},[_c('p',{staticClass:"link"},[_vm._v("地址：江苏省南京市玄武区运粮河西路101号江苏有线")]),_c('a',{staticClass:"link",attrs:{"href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011502011236","target":"_blank"}},[_c('img',{staticStyle:{"width":"15px","margin-right":"4px"},attrs:{"src":require("../../assets/gongan.png")}}),_vm._v(" 苏公网安备 32011502011236号 ")]),_c('a',{staticClass:"link",attrs:{"href":"http://ccm.mct.gov.cn/ccnt/sczr/service/business/emark/toDetail/cca6e731d1e54f35aa0844e732abd072","target":"_blank"}},[_c('img',{staticStyle:{"width":"15px","margin-right":"4px"},attrs:{"src":require("../../assets/suwenwang.png")}}),_vm._v(" 苏网文〔2020〕6098-190号 ")])]),_c('div',{staticClass:"telCon flexB"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("联系我们")]),_c('p',[_vm._v("公司邮箱：sjg@jscnnet.com")]),_c('p',[_vm._v("微信公众号：VisionLinkMedia")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("合作方式")]),_c('p',[_vm._v("广告合作：sjhl_business@jscnnet.com")]),_c('p',[_vm._v("媒体入驻微信：JSCN_SJHL")])])])])])])}]

export { render, staticRenderFns }
import axios from "axios";
//axios.defaults.timeout = 3000

//服务器地址
export const num = "1";
// export const ipHeader = "http://1.15.36.189:8992/api/"; //测试
// export const env = "test"; //测试

// export const ipHeaders = "http://102.60.28.199:8992/api/"; //本地
//export const ipHeaders = "http://102.60.26.95:8992/api/"; //本地
// export const env = "local"; //本地

export const ipHeader = "https://back.web.visionlinkmedia.cn/api/"; //生产
export const env = "product"; //生产

export let logoImage = () =>
  axios.get(`${ipHeader}jscn/logoInfo/getLogoInfoList`);

export let goodsImage = () =>
  axios.get(
    `${ipHeader}jscn/newDomesticGoodsLogo/getNewDomesticGoodsLogosList`
  );

export let swiperImage = () =>
  axios.get(`${ipHeader}jscn/homeCarousel/findHomeCarouselList`);

export let addImage = () =>
  axios.get(`${ipHeader}nsc/uc/horizon/horizonPlatform/getImageUrl/${num}`);

//首页新国货四张图片
export let addImages = () =>
  axios.get(`${ipHeader}nsc/uc/horizon/horizonPlatform/getJSImageUrl/${num}`);

//首页轮播图接口
export let slideShow = () =>
  axios.get(`${ipHeader}nsc/uc/news/enterpriseNews/getNewsImageUrl/${num}`);

//首页最新资讯接口
export let latestNews = () =>
  axios.get(`${ipHeader}nsc/uc/news/enterpriseNews/getInternetImageUrl/${num}`);

//首页新国货动态接口
export let goodDynamic = () =>
  axios.get(`${ipHeader}nsc/uc/news/enterpriseNews/getJsNewImageUrl/${num}`);

//首页视界动态接口
export let sightDynamic = () =>
  axios.get(`${ipHeader}nsc/uc/news/enterpriseNews/getWorldImageUrl/${num}`);

//视界印象发展历程接口
export let trend = () =>
  axios.get(`${ipHeader}nsc/uc/visual/visualImpression/findNewsCourseList`);

// export let trend = () =>
//   axios.get(`${ipHeader}uc/visual/visualImpression/getEvolution/${num}`);

//视界印象团队管理接口
export let team = () =>
  axios.get(`${ipHeader}nsc/uc/visual/visualImpression/getTeamList/${num}`);

//企业动态企业新闻接口
export let getNewsResults = () =>
  axios.get(`${ipHeader}nsc/uc/news/enterpriseNews/getNewsResultByType/3`);

//企业动态视界热点接口
export let getInternetList = () =>
  axios.get(`${ipHeader}nsc/uc/news/enterpriseNews/getNewsResultByType/4`);

//企业动态聚焦新国货接口
export let getJsNewList = () =>
  axios.get(`${ipHeader}nsc/uc/news/enterpriseNews/getNewsResultByType/5`);

//企业动态查看更多接口
export let getNewsResultes = (params) =>
  axios.get(
    `${ipHeader}nsc/uc/news/enterpriseNews/getMoreNewsListByType/${params}`
  );

//企业动态视界动态接口
export let getWorldList = (params) =>
  axios.get(`${ipHeader}nsc/uc/news/enterpriseNews/getWorldList/page`, {
    params: params,
  });

//企业动态视频资料接口
export let getVideoList = () =>
  axios.get(`${ipHeader}nsc/uc/news/enterpriseNews/getVideoInfoList/${num}`);

//企业动态企业形象接口
export let getBusinessPhotoList = () =>
  axios.get(`${ipHeader}nsc/uc/news/enterpriseNews/getCorporateList/${num}`);

//企业动态活动图集接口
export let getActivityList = () =>
  axios.get(`${ipHeader}nsc/uc/news/enterpriseNews/getActivityList/${num}`);

//企业动态新闻详情接口
export let getNewsById = (params) =>
  axios.get(`${ipHeader}nsc/uc/news/enterpriseNews/getNewsById/${params}`);

//企业动态活动介绍接口
export let listReferral = () =>
  axios.get(
    `${ipHeader}nsc/uc/activity/activityEntrance/getActivityInfoList/${num}`
  );

//企业动态活动入口接口
export let listEntrance = () =>
  axios.get(
    `${ipHeader}nsc/uc/activity/activityEntrance/getEntranceInfoList/${num}`
  );
//发展历程点击详情
export let getNewsDevelopId = (params) =>
  axios.get(`${ipHeader}jscn/newsCourse/findDetailById/${params}`);
//视界平台商家入驻表格
export let merchantEnter = (
  name,
  brand,
  productType,
  saleChannel,
  saleScale,
  contacts,
  phone
) =>
  axios({
    url: `${ipHeader}nsc/uc/enterprise/enterpriseSpace/saveOrUpdate`,
    method: "post",
    data: {
      name,
      brand,
      productType,
      saleChannel,
      saleScale,
      contacts,
      phone,
    },
  });
//页面访问量接口
export let visited = (topicCode) =>
  axios({
    url: `${ipHeader}nsc/uc/business/businessCount/saveOrUpdate`,
    method: "post",
    data: {
      topicCode,
    },
  });
//商家入驻表单
export let verify = () =>
  axios.get(`${ipHeader}nsc/uc/space/spaceConfig/spaceForm/${num}`);
//商家入驻表单
export let verifys = (list) =>
  axios({
    url: `${ipHeader}nsc/uc/space/spaceConfig/portalSave`,
    method: "post",
    data: {
      list,
    },
  });

export let getDynamicNews = (params) =>
  axios.get(
    `${ipHeader}nsc/uc/news/enterpriseNews/getNewsImageByType/${params}` //ipHeaders
  );

export let getDynamicNewsMain = (params) =>
  axios.get(
    `${ipHeader}nsc/uc/news/enterpriseNews/getNewsDetailById/${params}`
  );

export let getResultNews = (params) =>
  axios.get(
    `${ipHeader}nsc/uc/news/enterpriseNews/getNewsResultByType/${params}` //ipHeaders
  );

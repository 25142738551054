<template>
  <div id="app">
    <Pc />
  </div>
</template>
<script>
import Pc from "./components/PC";
import { env } from "./api/index";
export default {
  components: {
    Pc,
  },
  beforeCreate() {
    var browser = {
      versions: (function () {
        var u = navigator.userAgent;
        return {
          //移动终端浏览器版本信息
          trident: u.indexOf("Trident") > -1, //IE内核
          presto: u.indexOf("Presto") > -1, //opera内核
          webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
          gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
          mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
          ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
          android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或者uc浏览器
          iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
          iPad: u.indexOf("iPad") > -1, //是否iPad
          webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
          weixin: u.indexOf("MicroMessenger") > -1, //是否微信
          qq: u.match(/\sQQ/i) == " qq", //是否QQ
        };
      })(),
      language: (navigator.browserLanguage || navigator.language).toLowerCase(),
    };

    /* if (
      browser.versions.mobile ||
      browser.versions.ios ||
      browser.versions.android ||
      browser.versions.iPhone ||
      browser.versions.iPad
    ) {
      //移动端页面
      if (env == "local") {
        window.location.replace("http://localhost:8080/");
      }
      if (env == "test") {
        window.location.replace("http://1.15.36.189:82/");
      }
      if (env == "product") {
        window.location.replace("https://m.visionlinkmedia.cn/");
      }
    } else {
      // PC页面
    } */
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      if (to.name != from.name) {
        // console.log(to.name + " " + from.name);
        sessionStorage.removeItem("paltformNavBarSelected");
      }
      next();
    });

    if (this.greyFlag) {
      this.$nextTick(() => {
        document.getElementsByTagName("html")[0].style.webkitFilter =
          "grayscale(100%)";
        document.getElementsByTagName("html")[0].style.mozFilter =
          "grayscale(100%)";
        document.getElementsByTagName("html")[0].style.msFilter = "gray(100%)";
        document.getElementsByTagName("html")[0].style.oFilter =
          "grayscale(100%)";
        document.getElementsByTagName("html")[0].style.filter =
          "grayscale(100%)";
        document.getElementsByTagName("html")[0].style.filter =
          "progid:DXImageTransform.Microsoft.BasicImage(grayscale=1)";
      });
    }
  },
};
</script>
<style scoped>
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import bus from "./utils/bus";
Vue.prototype.$bus = bus; //将eventBus添加到原型

import "./assets/css/font.css";
import "./assets/css/public.css";

import "./assets/css/quill.snow.css";
import "./assets/css/quill.bubble.css";
import "./assets/css/quill.core.css";
Vue.config.productionTip = false;

import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require("./assets/icon_lazyload.png"), // 加载错误时候的图片
  loading: require("./assets/icon_lazyload.png"), // 加载中的图片
  attempt: 7,
  listenEvents: ["scroll"],
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div class="home">
    <div class="section-title">
      <span class="section-title-cn">关于我们</span
      ><span class="section-title-en">ABOUT US</span>
    </div>
    <div id="section-top">
      <div class="section-top">
        <p>
          江苏视界互联传媒有限公司,
          江苏省广电有线信息网络股份有限公司全资子公司。顺应5G改革浪潮，作为“内容+技术+产品+服务”多轮驱动的创新型互联网生态媒体平台，视界互联推出“视界观”移动客户端和“新国货电商”两大拳头产品。其中，“视界观”移动客户端倡导科技未来、品质生活，打造“权威的视频视频生产与传播端”；“新国货电商”通过与近百家龙头国货品牌厂家直连，构建出“乡村振兴”、“家乡好物”等一系列特色商城专区。
        </p>
        <p>
          未来，视界互联将秉承“创造用户价值、实现商业增值”的服务宗旨，发展成为在新媒体、电商、移动互联网市场极具竞争力的品牌。
        </p>
      </div>
      <div class="section-middle">
        <li>
          <div>
            <p>使命</p>
            <span class="section-middle-p">更新中......</span>
            <img src="../../assets/company profile.png" alt="公司简介" />
          </div>
        </li>
        <li>
          <div>
            <p>愿景</p>
            <span class="section-middle-p">更新中......</span>
            <img src="../../assets/development history.png" alt="公司简介" />
          </div>
        </li>
        <li>
          <div>
            <p>企业价值观</p>
            <span class="section-middle-p">更新中......</span>
            <img src="../../assets/enterprise culture.png" alt="公司简介" />
          </div>
        </li>
      </div>
    </div>
    <div id="section-bottom">
      <div class="section-bottom-center">
        <div class="section-bottom-left">
          <div
            v-for="(item, index) in tabs"
            :key="index"
            class="section-bottom-left-top"
            @mouseenter="mouse(index + 1)"
          >
            <div :class="{ 'mouse-div': tabBarSelected == index + 1 }">
              <span :class="{ 'mouse-span': tabBarSelected == index + 1 }">{{
                item.title
              }}</span
              ><br />
              <span
                :class="{ 'mouse-spans': tabBarSelected == index + 1 }"
                class="mouse-span-one"
                v-html="item.slogan"
              ></span
              ><br />
              <img
                src="../../assets/arrows.png"
                :class="{ 'mouse-img': tabBarSelected == index + 1 }"
              />
            </div>
          </div>
        </div>
        <div class="section-bottom-right">
          <div class="section-bottom-right-a">
            <div v-show="tabBarSelected === 1">
              <li
                v-for="(item, index) in arr1"
                :key="index"
                @click="toguide('/platform', 1)"
              >
                <img v-lazy="item.filePath" alt="" />
                <div class="section-bottom-right-p">
                  <span class="section-bottom-right-p-title">{{
                    item.platformTopic
                  }}</span>
                  <span
                    class="section-bottom-right-p-subtitle"
                    v-html="item.subtitle"
                  ></span>
                </div>
              </li>
            </div>
            <div v-show="tabBarSelected === 2">
              <li
                v-for="(item, index) in arr2"
                :key="index"
                @click="toguide('/platform', 2)"
              >
                <img v-lazy="item.filePath" alt="" />
                <div class="section-bottom-right-p">
                  <span class="section-bottom-right-p-title">{{
                    item.platformTopic
                  }}</span>
                  <span
                    class="section-bottom-right-p-subtitle"
                    v-html="item.subtitle"
                  ></span>
                </div>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section-title">
      <span class="section-title-cn">视界头条</span
      ><span class="section-title-en">VISIONLINK MEDIA HEADLINES</span>
    </div>
    <div id="main">
      <div class="main-left">
        <swiper :options="swiper" ref="swiper" v-if="images.length > 0">
          <swiper-slide
            class="img"
            v-for="(item, index) in images"
            :key="index"
          >
            <img :src="item.filePath" @click="todetail('/details', index)" />
            <div class="explain">
              <p>
                {{ item.newsTopic }}
              </p>
            </div>
          </swiper-slide>
          <div class="swiper-button-next" slot="button-next"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
        </swiper>
      </div>
      <div class="main-right">
        <div class="enterprise" @click="toguide('/dynamic', 'latest')">
          <h3>企业热点动态</h3>
          <div class="enterprise-bar"></div>
          <div>发现更多热点，聚集团队发展</div>
        </div>
      </div>
    </div>
    <div id="model">
      <div class="model">
        <div
          v-for="(item, index) in news"
          :key="index"
          class="model-top"
          @mouseenter="mouser(index + 1)"
        >
          <span :class="{ 'mouser-span': newBarSelected == index + 1 }">{{
            item.title
          }}</span>
        </div>
      </div>
      <div class="model-bottom">
        <div class="model-bottom-child">
          <div v-show="newBarSelected === 1">
            <div>
              <div>
                <li
                  v-for="(item, index) in new1"
                  :key="index"
                  @click="todetails(item.id)"
                >
                  <img v-lazy="item.absolutePath" alt="" />
                  <h3>{{ item.newsTopic }}</h3>
                  <h5>{{ item.subtitle }}</h5>
                </li>
              </div>
              <p @click="activejump(3)">加载更多</p>
            </div>
          </div>
          <div v-show="newBarSelected === 2">
            <div>
              <div>
                <li
                  v-for="(item, index) in new2"
                  :key="index"
                  @click="todetails(item.id)"
                >
                  <img v-lazy="item.absolutePath" alt="" />
                  <h3>{{ item.newsTopic }}</h3>
                  <h5>{{ item.subtitle }}</h5>
                </li>
              </div>
              <p @click="activejump(4)">加载更多</p>
            </div>
          </div>
          <div v-show="newBarSelected === 3">
            <div>
              <div>
                <li
                  v-for="(item, index) in new3"
                  :key="index"
                  @click="todetails(item.id)"
                >
                  <img v-lazy="item.absolutePath" alt="" />
                  <h3>{{ item.newsTopic }}</h3>
                  <h5>{{ item.subtitle }}</h5>
                </li>
              </div>
              <p @click="activejump(5)">加载更多</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section-title">
      <span class="section-title-cn">生态伙伴</span
      ><span class="section-title-en">ECOLOGICAL PARTNERS</span>
    </div>
    <div class="logo">
      <div
        class="logo-item"
        v-for="(item, index) in logoArray"
        :key="index"
        @click="jumpto(item.link, item.linkAddress)"
        :style="{ cursor: item.link === 0 ? '' : 'pointer' }"
      >
        <img :src="item.absolutePath" />
      </div>
    </div>
  </div>
</template>
<script>
import {
  slideShow,
  addImage,
  addImages,
  visited,
  getDynamicNews,
  logoImage,
} from "../../api/index";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  data() {
    return {
      tabBarSelected: 1,
      newBarSelected: 1,
      topicCode: 1001,
      arr1: [],
      arr2: [],
      new1: [],
      new2: [],
      new3: [],
      logoArray: [],
      images: [],
      timer: "",
      params1: "3",
      params2: "4",
      params3: "5",
      tabs: [
        {
          title: "视界观",
          slogan: "科技未来，品质生活<br>5G+4K/8K+AI视听生态媒体平台",
        },
        {
          title: "新国货",
          slogan: "品牌树格，消费互联<br>新国货品牌供应链融合服务平台",
        },
      ],
      news: [
        {
          title: "企业新闻",
        },
        {
          title: "视界热点",
        },
        {
          title: "聚焦新国货",
        },
      ],
      swiper: {
        loop: true,
        // autoHeight: true,
        loopAdditionalSlides: 2,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        slideToClickedSlide: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  created() {
    this.show();
    this.visit();
    this.world();
    this.newWorlds();
    this.$nextTick(() => {
      this.mouserup();

      this.sightDynamicNews();
      this.dynamicNews();
      window.scrollTo({
        top: "-100vh",
      });
    });
  },
  methods: {
    jumpto(link, linkAddress) {
      if (link === 0) {
        return;
      } else {
        window.open(linkAddress);
      }
    },
    visit() {
      this.visiting();
    },
    visiting() {
      visited(this.topicCode)
        .then()
        .catch((err) => {
          console.log(err);
        });
    },
    todetail(page, index) {
      this.$router.push({ path: page, query: { id: this.images[index].id } });
    },
    todetails(id) {
      this.$router.push({ path: "/details", query: { id: id } });
    },
    toguide(page, selectID) {
      if (page == this.$route.path) {
        //如果当前已经在这个页面不跳转 直接去
        let toElement = document.getElementById(selectID);
        toElement.scrollIntoView(true);
      } else {
        //否则跳转路由
        localStorage.setItem("toId", selectID);
        this.$router.push({ path: page });
      }
    },
    toguides(page, selectID) {
      if (page == this.$route.path) {
        this.send = selectID;
        this.$bus.$emit("a", this.send);
      } else {
        localStorage.setItem("toId", selectID);
        this.$router.push({ path: page });
      }
    },
    world() {
      addImage()
        .then((res) => {
          this.arr1 = res.data.data.slice(0, 4);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    show() {
      slideShow()
        .then((res) => {
          this.images = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      logoImage()
        .then((res) => {
          this.logoArray = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    mouserup() {
      addImages()
        .then((res) => {
          this.arr2 = res.data.data.slice(0, 4);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    newWorlds() {
      getDynamicNews(this.params1)
        .then((res) => {
          this.new1 = res.data.data.slice(0, 6);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sightDynamicNews() {
      getDynamicNews(this.params2)
        .then((res) => {
          this.new2 = res.data.data.slice(0, 6);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dynamicNews() {
      getDynamicNews(this.params3)
        .then((res) => {
          this.new3 = res.data.data.slice(0, 6);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    mouse(i) {
      this.tabBarSelected = i;
    },
    mouser(g) {
      this.newBarSelected = g;
    },
    activejump(id) {
      this.$router.push({ path: "/hotspot", query: { id: id } });
    },
  },
  mounted() {},

  computed: {},
  components: {
    swiper,
    swiperSlide,
  },
};
</script>
<style>
.home {
  /* width: 1280px; */
  margin: 0 auto;
}
.section-title {
  display: flex;
  width: 1280px;
  margin: 100px auto 0;
  font-size: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.section-title-cn {
  font-size: 30px;
  font-family: "siyuan-Medium";
  font-weight: 500;
  color: #333333;
  line-height: 31px;
  text-align: center;
}
.section-title-en {
  font-size: 30px;
  font-family: "siyuan-Medium";
  font-weight: 400;
  color: #333333;
  line-height: 31px;
  opacity: 0.1;
  text-align: center;
  margin-top: -50px;
}
#section-top {
  /* width: 100%; */
  width: 1280px;
  margin: 0 auto;
  /* height: 479px; */
}
#section-top .section-top {
  width: 1027px;
  /* height: 102px; */
  /* margin: 72px 170px 0 203px; */
  margin: 72px auto 0;
  text-align: justify;
}
#section-top .section-top p {
  font-size: 16px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  text-indent: 2em;
}
#section-top .section-top p {
  margin-bottom: 20px;
}
#section-top .section-top p span {
  font-weight: bold;
}
#section-top .section-middle {
  width: 1027px;
  margin: 68px auto 0;
  display: flex;
  height: 167px;
  justify-content: space-between;
}
#section-top .section-middle li {
  display: inline-block;
  height: 167px;
  width: 267px;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
}
#section-top .section-middle li a {
  display: block;
  height: 167px;
  width: 267px;
  position: relative;
}
#section-top .section-middle li div:before {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}
#section-top .section-middle li div:after {
  display: block;
  content: "";
  height: 167px;
  width: 267px;
  background: rgba(0, 82, 217, 0.7);
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
#section-top .section-middle li p {
  display: block;
  float: left;
  font-size: 24px;
  height: 23px;
  min-width: 80px;
  font-family: "siyuan-Medium";
  text-align: justify;
  text-align-last: justify;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -20px;
  z-index: 3;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  transform: translate(-50%, 0);
  padding-bottom: 3px;
  transition: border-bottom 0.3s ease-out;
  padding-bottom: 5px;
  -webkit-transition: width 1s linear;
}
#section-top .section-middle li p:after {
  content: " ";
  width: 0;
  height: 2px;
  background: #fff;
  bottom: -7px;
  left: 0;
  position: absolute;
  opacity: 0;
  -webkit-transition: all 0.2s linear;
}
#section-top .section-middle li img {
  display: block;
  height: 167px;
  width: 267px;
  position: absolute;
  left: 0px;
  top: 0px;
  transform: scale(1);
  transition: transform 0.3s ease-out;
}
#section-top .section-middle li div:hover:after {
  opacity: 1;
}
#section-top .section-middle li div:hover:before {
  opacity: 0;
}
#section-top .section-middle li div:hover p:after {
  opacity: 1;
  left: 0;
  width: 100%;
  margin-top: -30px;
}
#section-top .section-middle li div:hover p {
  margin-top: -30px;
  transition: transform 1s ease-out;
}
#section-top .section-middle li div:hover img {
  transform: scale(1.2);
}
#section-top .section-middle li .section-middle-p {
  font-size: 14px;
  font-family: "siyuan-Regular";
  font-weight: 400;
  color: #ffffff;
  display: none;
  float: left;
  height: 23px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 3;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  transform: translate(-50%, 0);
  padding-bottom: 3px;
  transition: border-bottom 0.3s ease-out;
  padding-bottom: 5px;
  -webkit-transition: width 1s linear;
  margin-top: 20px;
  white-space: nowrap;
}
#section-top .section-middle li div:hover .section-middle-p {
  display: block;
}
#section-bottom {
  width: 100vw;
  margin-top: 72px;
  background-color: rgb(59, 59, 59);
}
.section-bottom-center {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
}
#section-bottom .section-bottom-left {
  width: 424px;
}
#section-bottom .section-bottom-left-top {
  width: 424px;
  height: 248px;
  background-color: rgb(59, 59, 59);
  color: #666666;
  position: relative;
}
#section-bottom .section-bottom-left-top div {
  width: 240px;
  /* height: 64px; */
  position: absolute;
  top: 65px;
  left: 105px;
}
#section-bottom .section-bottom-left-top .mouse-div {
  top: 67px;
  left: 52px;
  padding-left: 26px;
  border-left: 4px solid #0075ff;
  transition: all 0.5s ease;
}
#section-bottom .section-bottom-left-top div span {
  display: inline-block;
  font: 28px/28px "siyuan-Normal";
}
#section-bottom .section-bottom-left-top div .mouse-span-one {
  margin: 12px 0;
  display: inline-block;
  font: 12px/24px "siyuan-Normal";
  /* margin-bottom: 22px; */
}
#section-bottom .section-bottom-left-top .mouse-div .mouse-span {
  color: #fff;
}
#section-bottom .section-bottom-left-top .mouse-div .mouse-spans {
  margin: 14px 0;
  font: 14px/24px "siyuan-Regular";
  color: #fff;
}
#section-bottom .section-bottom-left-top div img {
  opacity: 0;
}
#section-bottom .section-bottom-left-top .mouse-div .mouse-img {
  width: 23px;
  height: 14px;
  opacity: 1;
  transition: all 0.5s linear;
}
#section-bottom .section-bottom-right {
  width: 856px;
}
#section-bottom .section-bottom-right-a div {
  width: 856px;
  display: flex;
  flex-wrap: wrap;
}
#section-bottom .section-bottom-right-a div li {
  width: 50%;
  height: 248px;
  border: none;
  cursor: pointer;
  position: relative;
}
#section-bottom .section-bottom-right-a div li img {
  width: 428px;
  height: 248px;
}
#section-bottom .section-bottom-right-a div li::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
#section-bottom .section-bottom-right-p {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
}
#section-bottom .section-bottom-right-p-title {
  font-family: "siyuan-Medium";
  width: 388px !important;
  font-size: 20px;
  color: white;
  transition: ease all 0.3s;
  position: relative;
}
#section-bottom .section-bottom-right-p-title:after {
  content: "";
  display: block;
  position: absolute;
  width: 36px;
  height: 20px;
  opacity: 0;
  top: 140%;
  right: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center left;
  background-image: url(../../assets/icon_arrow_r.png);
  transition: ease all 0.3s;
}
#section-bottom .section-bottom-right-p-subtitle {
  font-family: "siyuan-Regular";
  font-size: 14px;
  color: white;
  transition: ease all 0.3s;
  display: none;
}
#section-bottom
  .section-bottom-right-a
  div
  li:hover
  .section-bottom-right-p-subtitle {
  display: block;
}
#section-bottom
  .section-bottom-right-a
  div
  li:hover
  .section-bottom-right-p-title:after {
  right: 0px;
  opacity: 1;
}
#main {
  width: 1280px;
  margin: 0 auto;
  height: 564px;
  position: relative;
}
#main .main-left {
  width: 568px;
  height: 414px;
  position: absolute;
  top: 72px;
  left: 145px;
  cursor: pointer;
}
#main .main-left .img {
  width: 568px;
  height: 414px;
  position: relative;
}
#main .main-left .img::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
#main .main-left .img img {
  width: 568px;
  height: 414px;
  object-fit: cover;
}
.swiper-button-prev {
  left: 490px;
  background-image: url("../../assets/icon_prev_light.png");
  background-size: 12px 21px;
}
.swiper-button-next {
  right: 20px;
  background-image: url("../../assets/icon_next_light.png");
  background-size: 12px 21px;
}
.swiper-button-prev,
.swiper-button-next {
  top: 90%;
}
#main .main-left .explain {
  width: 568px;
  height: 40px;
  position: absolute;
  bottom: 10px;
}
#main .main-left .explain p {
  margin-left: 40px;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 420px;
}
#main .main-left .symbol {
  width: 70px;
  height: 26px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 50px;
  bottom: 70px;
}
#main .main-left .symbol .btn {
  width: 20px;
  height: 30px;
  display: inline-block;
  color: #fff;
  font-size: 30px;
}
#main .main-right {
  width: 568px;
  height: 414px;
  position: absolute;
  top: 62px;
  left: 713px;
}
#main .main-right .enterprise {
  width: 220px;
  height: 190px;
  margin: 130px 0 0 130px;
}
#main .main-right .enterprise h3 {
  font-size: 28px;
  font-family: "siyuan-Normal";
  font-weight: 400;
  color: #000000;
  margin-bottom: 14px;
  cursor: pointer;
}
#main .main-right .enterprise .enterprise-bar {
  width: 200px;
  height: 4px;
  background: #0075ff;
  margin-top: 14px;
  margin-left: 33px;
  border-radius: 2px;
}
#main .main-right .enterprise div {
  font-family: "siyuan-Regular";
  margin-top: 14px;
  width: 216px;
  height: 16px;
  font-size: 16px;
  font-weight: 200;
  color: #333333;
  cursor: pointer;
}

#model {
  margin: 0 auto;
  width: 1280px;
}
#model .model {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  background: #3b3b3b;
  text-align: center;
}
#model .model .model-top {
  width: calc(640px * 2 / 3);
  height: calc(169px * 2 / 3);
  text-align: center;
}
#model .model .model-top span {
  font-size: 24px;
  line-height: calc(169px * 2 / 3);
  padding: 0 20px;
  font-weight: 500;
  color: #ffffff;
  opacity: 0.4;
  font-family: "siyuan-Normal";
}
#model .model .model-top .mouser-span {
  border-left: 2px solid #0075ff;
  opacity: 1;
}

#model .model-bottom {
  width: 1280px;
}
#model .model-bottom .model-bottom-child div {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
#model .model-bottom .model-bottom-child li {
  width: calc(640px * 2 / 3);
  display: inline-block;
  height: calc(370px * 2 / 3);
  background-color: #dfdfdf;
  position: relative;
  cursor: pointer;
}
#model .model-bottom .model-bottom-child li::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
#model .model-bottom .model-bottom-child li img {
  width: calc(640px * 2 / 3);
  height: calc(370px * 2 / 3);
  object-fit: cover;
}
#model .model-bottom .model-bottom-child li h3 {
  font-size: calc(29px * 2 / 3);
  position: absolute;
  left: 24px;
  bottom: calc(85px * 2 / 3);
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 375px;
}
#model .model-bottom .model-bottom-child li h5 {
  font-size: calc(26px * 2 / 3);
  position: absolute;
  left: calc(80px * 2 / 3);
  bottom: calc(35px * 2 / 3);
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 330px;
}
#model .model-bottom .model-bottom-child p {
  width: 1280px;
  margin: 0 auto;
  font-size: calc(25px * 2 / 3);
  line-height: calc(77px * 2 / 3);
  height: calc(77px * 2 / 3);
  background-color: #3a3a3a;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
  cursor: pointer;
}
#model .model-bottom .model-bottom-child p a {
  color: #fff;
}

.logo {
  width: 1072px;
  margin: 72px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.logo-item {
  width: 110px;
  /* height: 52px; */
  margin: 0 12px 38px 12px;
}
.logo-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../pages/Home";
// import Impression from "../pages/Impression";
// import Platform from "../pages/Platform";
// import Dynamic from "../pages/Dynamic";
// import Dispark from "../pages/Dispark";
// import Details from "../pages/Details";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/impression",
    name: "Impression",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Impression"),
  },
  {
    path: "/platform",
    name: "Platform",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Platform"),
  },
  {
    path: "/dynamic",
    name: "Dynamic",
    component: () => import(/* webpackChunkName: "about" */ "../pages/Dynamic"),
  },
  {
    path: "/dispark",
    name: "Dispark",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Dispark/dispark.vue"),
  },
  {
    path: "/hotspot",
    name: "Hotspot",
    component: () => import(/* webpackChunkName: "about" */ "../pages/Hotspot"),
  },
  {
    path: "/activity",
    name: "Activity",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Activity"),
  },
  {
    path: "/mediaVideo",
    name: "MediaVideo",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/MediaVideo"),
  },
  {
    path: "/businessImg",
    name: "BusinessImg",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/BusinessImg"),
  },
  {
    path: "/details",
    name: "Details",
    component: () => import(/* webpackChunkName: "about" */ "../pages/Details"),
  },
  {
    path: "/list",
    name: "List",
    component: () => import(/* webpackChunkName: "about" */ "../pages/List"),
  },
  {
    path: "/newsdetails",
    name: "NewsDetails",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/NewsDetails"),
  },
  {
    path: "/developnews",
    name: "Developnews",
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/Developnews"),
  },
  {
    path: "/cases",
    name: "Cases",
    component: () => import(/* webpackChunkName: "about" */ "../pages/Cases"),
  },
  {
    path: "*",
    component: Home,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

//全局守卫
router.beforeEach((to, from, next) => {
  var flag = false;
  routes.forEach((element) => {
    if (
      to.path
        .substring(1, to.path.length - 1)
        .toLowerCase()
        .indexOf(
          element.path.substring(1, element.path.length - 1).toLowerCase()
        ) > -1
    ) {
      flag = true;
    }
  });
  if (!flag) {
    next({
      path: "/",
    });
  } else {
    next();
  }
});

export default router;
